<template>
    <div>
        <div class="header-section">
            <div class="title-section">{{ $t('dashboard.responsibilities_without_frequency')}}</div>
        </div>
        <div class="box-table-dashboard" v-if="loaded">
            <template v-if="responsibilities.length">
                <div class="header">
                    <div class="column-filter column-name">
                        <div class="text">{{ $t('general.name')}}</div>
                    </div>
                    <div class="column-filter column-description" v-if="$resize && $mq.above(521)">
                        <div class="text">{{ $t('general.procedures')}}</div>
                    </div>
                    <div class="column-filter column-trigger" v-if="$resize && $mq.above(521)">
                        <div class="text">{{ $t('general.trigger')}}</div>
                    </div>
                    <div class="column-filter column-role" v-if="$resize && $mq.above(521)">
                        <div class="text">{{ $t('general.role')}}</div>
                    </div>
                </div>
                <div class="content">
                    <div class="table-row" v-for="responsibility in responsibilities">
                        <!-- Name -->
                        <div class="column-name-desc column-name clickable" @click="openResponsibility(responsibility)">
                            <div class="name">{{ responsibility.name }}</div>
                        </div>
                        <!-- Description -->
                        <div class="column-description ">
                            <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 0, hide: 0}">
                                <div class="name">{{ responsibility.procedures.join(', ') }}</div>

                                <template slot="popover">
                                    <div class="simple-text">{{ responsibility.procedures.join(', ') }}</div>
                                </template>
                            </v-popover>
                        </div>
                        <!-- Trigger -->
                        <div class="column-name-desc column-trigger">
                            <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 0, hide: 0}">                           
                                 <div class="name">{{ responsibility.trigger }}</div>

                                <template slot="popover">
                                    <div class="simple-text">{{ responsibility.trigger }}</div>
                                </template>
                            </v-popover>
                        </div>
                        <!-- Role -->
                        <div class="column-name-desc column-role ">
                            <div class="name">{{ responsibility.role_name }}</div>
                        </div>
                    </div>
                    <div class="table-row view-more" @click="viewMoreResponsibilities()" v-if="!allResponsibilities">
                        <div class="text">
                            {{ $t('general.view_more')}}
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="empty-tasks">
                    <div class="icon">
                        <icon-stars class="stars-empty"/>
                    </div>
                    <div class="text">
                        <div class="main">{{ $t('dashboard.no_responsibilities_without_frequency')}}</div>
                    </div>
                </div>
            </template>
        </div>
        <loader-evaluation v-else/>
    </div>
</template>

<script>
	import IconStars from '../../Icons/Stars'
	import IconInfo from '../../Icons/Info'

	import LoaderEvaluation from '../../PagesLoaders/DashboardEvaluation'

	export default {
		components: {
			IconStars,
            IconInfo,
			LoaderEvaluation
		},
		data() {
			return {
				loaded: false,
				responsibilities: [],
				optionsUnits: {
					"€": 'Euro',
					"$": 'Dolar',
					"%": 'Procent',
				},
                page: 0,
            	infiniteId: 1,
                allResponsibilities: false
			}
		},
		mounted() {
			this.viewMoreResponsibilities()
		},
		methods: {
			viewMoreResponsibilities(){
                var paramsCall = {
					view_more: this.page * 5
                }
				axios.get(`/${this.$auth.user().slug}/responsibilities`, { params: paramsCall })
				.then(({data}) => {
					this.page++
                    this.responsibilities.push(...data.data)
                    if(data.data.length < 5){
                        this.allResponsibilities = true
                    }
                  
				})
				.finally(() => {
					setTimeout(() => { this.loaded = true }, 0)
				})
			},
            openResponsibility(responsibility){
                this.$root.$emit('open_modal', 'show_responsibility', {
                    from: 'user',
                    userId: this.$auth.user().id,
                    userSlug: this.$auth.user().slug,
                    userRight: this.$auth.user().right,
                    id: responsibility.id,
                });
            }
        }
	}
</script>